var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "inherit" } }, [
    _c("section", { attrs: { id: "ecommerce-header" } }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "ecommerce-header-items" }, [
            _c(
              "div",
              { staticClass: "result-toggler" },
              [
                _c("feather-icon", {
                  staticClass: "d-block d-lg-none",
                  attrs: { icon: "MenuIcon", size: "21" },
                  on: {
                    click: function($event) {
                      _vm.mqShallShowLeftSidebar = true
                    }
                  }
                }),
                _c("div", { staticClass: "search-results" }, [
                  _vm._v(" " + _vm._s(_vm.totalProducts) + " results found ")
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "view-options d-flex" },
              [
                _c(
                  "b-dropdown",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(113, 102, 240, 0.15)",
                        expression: "'rgba(113, 102, 240, 0.15)'",
                        modifiers: { "400": true }
                      }
                    ],
                    attrs: {
                      text: _vm.sortBy.text,
                      right: "",
                      variant: "outline-primary"
                    }
                  },
                  _vm._l(_vm.sortByOptions, function(sortOption) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: sortOption.value,
                        on: {
                          click: function($event) {
                            _vm.sortBy = sortOption
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(sortOption.text) + " ")]
                    )
                  }),
                  1
                ),
                _c(
                  "b-form-radio-group",
                  {
                    staticClass: "ml-1 list item-view-radio-group",
                    attrs: {
                      buttons: "",
                      size: "sm",
                      "button-variant": "outline-primary"
                    },
                    model: {
                      value: _vm.itemView,
                      callback: function($$v) {
                        _vm.itemView = $$v
                      },
                      expression: "itemView"
                    }
                  },
                  _vm._l(_vm.itemViewOptions, function(option) {
                    return _c(
                      "b-form-radio",
                      { key: option.value, attrs: { value: option.value } },
                      [
                        _c("feather-icon", {
                          attrs: { icon: option.icon, size: "18" }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "ecommerce-searchbar mt-1" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c(
                  "b-input-group",
                  { staticClass: "input-group-merge" },
                  [
                    _c("b-form-input", {
                      staticClass: "search-product",
                      attrs: { placeholder: "Search Product" },
                      model: {
                        value: _vm.filters.q,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "q", $$v)
                        },
                        expression: "filters.q"
                      }
                    }),
                    _c(
                      "b-input-group-append",
                      { attrs: { "is-text": "" } },
                      [
                        _c("feather-icon", {
                          staticClass: "text-muted",
                          attrs: { icon: "SearchIcon" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "section",
      { class: _vm.itemView },
      _vm._l(_vm.products, function(product) {
        return _c(
          "b-card",
          {
            key: product.id,
            staticClass: "ecommerce-card",
            attrs: { "no-body": "" }
          },
          [
            _c(
              "div",
              { staticClass: "item-img text-center" },
              [
                _c(
                  "b-link",
                  {
                    attrs: {
                      to: {
                        name: "apps-e-commerce-product-details",
                        params: { slug: product.slug }
                      }
                    }
                  },
                  [
                    _c("b-img", {
                      staticClass: "card-img-top",
                      attrs: {
                        alt: product.name + "-" + product.id,
                        fluid: "",
                        src: product.image
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-card-body",
              [
                _c("div", { staticClass: "item-wrapper" }, [
                  _c("div", { staticClass: "item-rating" }, [
                    _c(
                      "ul",
                      { staticClass: "unstyled-list list-inline" },
                      _vm._l(5, function(star) {
                        return _c(
                          "li",
                          {
                            key: star,
                            staticClass: "ratings-list-item",
                            class: { "ml-25": star - 1 }
                          },
                          [
                            _c("feather-icon", {
                              class: [
                                { "fill-current": star <= product.rating },
                                star <= product.rating
                                  ? "text-warning"
                                  : "text-muted"
                              ],
                              attrs: { icon: "StarIcon", size: "16" }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]),
                  _c("div", [
                    _c("h6", { staticClass: "item-price" }, [
                      _vm._v(" $" + _vm._s(product.price) + " ")
                    ])
                  ])
                ]),
                _c(
                  "h6",
                  { staticClass: "item-name" },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "text-body",
                        attrs: {
                          to: {
                            name: "apps-e-commerce-product-details",
                            params: { slug: product.slug }
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(product.name) + " ")]
                    ),
                    _c(
                      "b-card-text",
                      { staticClass: "item-company" },
                      [
                        _vm._v(" By "),
                        _c("b-link", { staticClass: "ml-25" }, [
                          _vm._v(" " + _vm._s(product.brand) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("b-card-text", { staticClass: "item-description" }, [
                  _vm._v(" " + _vm._s(product.description) + " ")
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item-options text-center" },
              [
                _c("div", { staticClass: "item-wrapper" }, [
                  _c("div", { staticClass: "item-cost" }, [
                    _c("h4", { staticClass: "item-price" }, [
                      _vm._v(" $" + _vm._s(product.price) + " ")
                    ])
                  ])
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-wishlist",
                    attrs: { variant: "light", tag: "a" },
                    on: {
                      click: function($event) {
                        return _vm.toggleProductInWishlist(product)
                      }
                    }
                  },
                  [
                    _c("feather-icon", {
                      staticClass: "mr-50",
                      class: { "text-danger": product.isInWishlist },
                      attrs: { icon: "HeartIcon" }
                    }),
                    _c("span", [_vm._v("Wishlist")])
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-cart",
                    attrs: { variant: "primary", tag: "a" },
                    on: {
                      click: function($event) {
                        return _vm.handleCartActionClick(product)
                      }
                    }
                  },
                  [
                    _c("feather-icon", {
                      staticClass: "mr-50",
                      attrs: { icon: "ShoppingCartIcon" }
                    }),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          product.isInCart ? "View In Cart" : "Add to Cart"
                        )
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      1
    ),
    _c(
      "section",
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c("b-pagination", {
                  attrs: {
                    "total-rows": _vm.totalProducts,
                    "per-page": _vm.filters.perPage,
                    "first-number": "",
                    align: "center",
                    "last-number": "",
                    "prev-class": "prev-item",
                    "next-class": "next-item"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "prev-text",
                      fn: function() {
                        return [
                          _c("feather-icon", {
                            attrs: { icon: "ChevronLeftIcon", size: "18" }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "next-text",
                      fn: function() {
                        return [
                          _c("feather-icon", {
                            attrs: { icon: "ChevronRightIcon", size: "18" }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.filters.page,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "page", $$v)
                    },
                    expression: "filters.page"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }